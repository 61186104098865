var exports = {};

/**
 * Check if a URL string is an object URL from `URL.createObjectURL`.
 *
 * @param {string} url
 * @returns {boolean}
 */
exports = function isObjectURL(url) {
  return url.startsWith("blob:");
};

export default exports;